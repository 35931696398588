import React, { Component } from 'react';
// import { Link } from "gatsby"
import Slider from 'react-slick';

import { Slider6 } from "../services/script";


import logos9 from "../images/100weatherhead.jpg"
import logos10 from "../images/neosuccess.jpg"
import logos11 from "../images/weatherhead20.jpg"
import logos12 from "../images/cosa10.jpg"
import logos13 from "../images/weatherhead_2009.jpg"
// import logos14 from "../images/aba_2008.jpg"
import logos15 from "../images/aba_2009.jpg"
// import logos16 from "../images/logos/16.png"
// import logos9 from "../assets/images/logos/9.png"
// import logos10 from "../assets/images/logos/10.png"
// import logos11 from "../assets/images/logos/11.png"
// import logos12 from "../assets/images/logos/12.png"
// import logos13 from "../assets/images/logos/13.png"
// import logos14 from "../assets/images/logos/14.png"
// import logos15 from "../assets/images/logos/15.png"
// import logos16 from "../assets/images/logos/16.png"

class LogoBlock extends Component {

    render() {
        return (
            <section className="section-b-space">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <Slider {...Slider6} className="slide-6 no-arrow">
                                <div>
                                    <div className="logo-block">
                                        {/* <Link to={null}> */}
                                            <img src={logos9} alt="Weatherhead 100 Award Logo" />
                                        {/* </Link> */}
                                    </div>
                                </div>
                                <div>
                                    <div className="logo-block">
                                        {/* <Link to={null}> */}
                                            <img src={logos10} alt="NEO Success Award Logo" />
                                        {/* </Link> */}
                                    </div>
                                </div>
                                <div>
                                    <div className="logo-block">
                                        {/* <Link to={null}> */}
                                            <img src={logos11} alt="Weatherhead 100 Award Logo" />
                                        {/* </Link> */}
                                    </div>
                                </div>
                                <div>
                                    <div className="logo-block">
                                        {/* <Link to={null}> */}
                                            <img src={logos12} alt="COSE 10 Under 10 Award Logo" />
                                        {/* </Link> */}
                                    </div>
                                </div>
                                <div>
                                    <div className="logo-block">
                                        {/* <Link to={null}> */}
                                            <img src={logos13} alt="Weatherhead 100 Award Logo" />
                                        {/* </Link> */}
                                    </div>
                                </div>
                                {/* <div>
                                    <div className="logo-block">
                                        <Link to={null}>
                                            <img src={logos14} alt="" />
                                        </Link>
                                    </div>
                                </div> */}
                                <div>
                                    <div className="logo-block">
                                        {/* <Link to={null}> */}
                                            <img src={logos15} alt="American Business Awards Finalist Logo" />
                                        {/* </Link> */}
                                    </div>
                                </div>
                                {/* <div>
                                    <div className="logo-block">
                                        <Link to={null}>
                                            <img src={logos16} alt="" />
                                        </Link>
                                    </div>
                                </div> */}
                            </Slider>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default LogoBlock;